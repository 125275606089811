/**
 * Created by Giannis Kallergis on 2018-12-30
 */

import React from "react";

/** Components */
import Layout from "../components/Layout";
import Wrapper from "../components/Wrapper";
import SEO from "../components/SEO";
import Header from "../components/Header";
import Content from "../components/Content";
import AuthorHeader from "../components/AuthorHeader";

// noinspection JSUnusedGlobalSymbols
export default class SuccessPage extends React.PureComponent {
  render() {
    return (
      <Layout>
        <Wrapper>
          <SEO title={`Contact`} />
          <Header>
            <AuthorHeader />
          </Header>
          <Content hasHeader>
            <h1>Your message was sent!</h1>
            <p>Thank you for reaching out! I'll answer your request as soon as possible.</p>
          </Content>
        </Wrapper>
      </Layout>
    );
  }
}
